import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Paper, Link, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import background from '../assets/img/background-login.png';
import logo from '../assets/img/logo.png';
import { registerService } from '../services/authServices';
import { IRegister } from '../interfaces/IUser';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IRegister>({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    celular: '',
    cedulaProfesional: '',
    universidad: '',
    email: '',
    estadoOrigen: '',
    password: '',
  });

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = (): boolean => {
    const { nombre, apellidoPaterno, apellidoMaterno, celular, cedulaProfesional, universidad, email, estadoOrigen, password } = formData;

    if (!nombre || !apellidoPaterno || !apellidoMaterno || !celular || !cedulaProfesional || !universidad || !email || !estadoOrigen || !password) {
      setError('Por favor, complete todos los campos');
      setDialogOpen(true);
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Por favor, ingrese un correo electrónico válido');
      setDialogOpen(true);
      return false;
    }

    const celularPattern = /^[0-9]{10}$/;
    if (!celularPattern.test(celular)) {
      setError('El número de celular debe tener 10 dígitos');
      setDialogOpen(true);
      return false;
    }

    setError(null);
    return true;
  };

  const handleRegister = async () => {
    if (!validateForm()) return;
  
    setSuccess(null);
    try {
      const response = await registerService(formData);
      if (response && response.message === "Usuario creado e inscrito exitosamente.") {
        setSuccess("Registro exitoso. Ahora puede iniciar sesión.");
        setDialogOpen(true);
        setTimeout(() => navigate('/'), 2000); // Redirect to login after 2 seconds
      }
    } catch (err) {
      setError("No se pudo completar el registro. Inténtelo de nuevo.");
      setDialogOpen(true);
      console.error(err);
    }
  };

  return (
    <Box display="flex" height="100vh">
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: '#f5f7fa', width: { xs: '100%', sm: '50%', md: '40%' } }}
      >
        <Paper
          elevation={1}
          sx={{
            paddingTop: '5%',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 2,
            pb: 5,
            width: '100%',
            maxWidth: '60%',
            minHeight: '80vh',
            maxHeight: '80vh',
            mx: 2,
            overflowY: "scroll",
            textAlign: 'center',
          }}
        >
          <img src={logo} width={100} height={100} alt='ExamSys logo' />
          <Typography variant="h6" fontWeight={'bold'} gutterBottom>Registrate</Typography>

          <TextField
            fullWidth
            label="Nombre"
            margin="normal"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Apellido Paterno"
            margin="normal"
            name="apellidoPaterno"
            value={formData.apellidoPaterno}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Apellido Materno"
            margin="normal"
            name="apellidoMaterno"
            value={formData.apellidoMaterno}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Celular"
            margin="normal"
            name="celular"
            type="tel"
            value={formData.celular}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Cédula Profesional"
            margin="normal"
            name="cedulaProfesional"
            value={formData.cedulaProfesional}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Universidad"
            margin="normal"
            name="universidad"
            value={formData.universidad}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Estado de Origen"
            margin="normal"
            name="estadoOrigen"
            value={formData.estadoOrigen}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />

          <Button
            fullWidth
            variant="contained"
            onClick={handleRegister}
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: 'primary',
              color: 'white',
              '&:hover': { backgroundColor: 'secondary' },
            }}
          >
            Registrarse
          </Button>
          <Typography variant="body2" color="textSecondary">
            ¿Ya tienes una cuenta? <Link href="/" variant="body2" underline="hover">Iniciar sesión</Link>
          </Typography>
        </Paper>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{success ? "Registro Exitoso" : "Error"}</DialogTitle>
        <DialogContent>
          <Typography>{success || error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Register;
