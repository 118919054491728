// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardL from './pages/DashboardLayout';
import DashboardWelcome from './components/Dashboard/DashboardWelcome';
import DashboardExams from './components/Dashboard/DashboardExams';
import InscribirExamen from './components/Dashboard/InscribirExamen';
import Navbar from './components/Navbar';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import SecurityLayer from './components/SecurityLayer'; // Importar el componente SecurityLayer
import PrintBlocker from './components/PrintBlocker'; // Importar PrintBlocker
import TextSelectionBlocker from './components/TextSelectionBlocker'; // Importar TextSelectionBlocker

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <SecurityLayer />
          <PrintBlocker />
          <TextSelectionBlocker/>
          <Routes>
            {/* Rutas Públicas */}
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* Rutas Protegidas */}
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<DashboardL />}>
                <Route index element={<DashboardWelcome />} />
                <Route path="examenes" element={<DashboardExams />} />
                <Route path="resultados" element={<DashboardExams />} />
                <Route path="inscribir" element={<InscribirExamen />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
