import { Examen, Modulo } from "../interfaces/IExam";

import axios from 'axios';

export const fetchExamsService = async (idUsuario: string): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/pendientes/usuarios/'+idUsuario);
  return response.data;
};

export const fetchModules = async (examId: number): Promise<Modulo[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/generar/'+examId+'?cantidad=300');
  return response.data;
}; 

export const getAvailableExams = async (): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/disponibles');
  return response.data;
};
  
export const getFinishedExams = async (userId: string): Promise<Examen[]> => {
  const response = await axios.get('https://backexams-624915669123.us-central1.run.app/api/exams/terminados/usuarios/'+userId);
  return response.data;
};
  

export const inscribirExamen = async (usuarioId: string, examenId: number)  => {
  console.log("ENTRA INSCRIBIR EXAMEN");
  try {
     const response = await axios.post<any>('https://backexams-624915669123.us-central1.run.app/api/exams/inscribir', 
      {
        usuarioId: usuarioId,
        examenId: examenId
      }
     );
     return response.data;

  } catch (error: any) {
      throw new Error(error);
  }
};

export const guardarRespuestasExamen = async (usuarioId: string, examenId: number, examResult: any)  => {
  console.log("ENTRA <SUBIR RESPUESTAS> EXAMEN");
  try {                                 
     const response = await axios.post(`https://backexams-624915669123.us-central1.run.app/api/answers/guardarrespuestas/exam/${examenId}/usuario/${usuarioId}`, examResult)
     .then(response => {
       console.log("Exam submitted:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
};

export const terminarExamen = async (usuarioId: string, examenId: number)  => {
  console.log("ENTRA <TERMINAR> EXAMEN");
  try {
     const response = await   axios.put(`https://backexams-624915669123.us-central1.run.app/api/answers/terminar/exam/${examenId}/usuario/${usuarioId}`)
     .then(response => {
       console.log("Exam submitted:", response.data);
     });

  } catch (error: any) {
      throw new Error(error);
  }
};

