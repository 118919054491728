import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Button,
  Alert,
  CardContent,
  Card,
} from "@mui/material";
import axios from "axios";
import Question from "./Question";
import { Examen, Modulo } from "../../interfaces/IExam";
import {
  guardarRespuestasExamen,
  terminarExamen,
} from "../../services/examServices";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { logoutService } from "../../services/authServices";

interface QuestionnaireProps {
  examModules: Modulo[];
  exam: Examen;
}

const Questionnaire: React.FC<QuestionnaireProps> = ({ examModules, exam }) => {
  const [moduleIndex, setModuleIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState<string | undefined>();
  const [answers, setAnswers] = useState<
    { preguntaId: string; opcionId: string }[]
  >([]);
  const [showResult, setShowResult] = useState(false);
  const [showModuleEnd, setShowModuleEnd] = useState(false);
  const [lastModule, setLastModule] = useState(false);

  const [examStatus, setExamStatus] = useState("en_progreso");

  const totalSeconds = exam.tiempoLimite * 60;
  const timeLeftRef = useRef(totalSeconds);
  const [displayedTime, setDisplayedTime] = useState(totalSeconds);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const currentModule = examModules[moduleIndex];
  const currentQuestion = currentModule.preguntas[questionIndex];
  const totalQuestions = examModules.reduce(
    (total, module) => total + module.preguntas.length,
    0
  );
  const currentQuestionNumber =
    examModules
      .slice(0, moduleIndex)
      .reduce((total, module) => total + module.preguntas.length, 0) +
    questionIndex;

  let progress = (currentQuestionNumber / totalQuestions) * 100;

  const countdownExam = useCallback(() => {
    if (timeLeftRef.current <= 1) {
      clearInterval(timerRef.current!);
      alert("Se terminó el tiempo");
      setDisplayedTime(0);
    } else {
      timeLeftRef.current -= 1;
      setDisplayedTime(timeLeftRef.current);
    }
  }, []);

  useEffect(() => {
    timerRef.current = setInterval(countdownExam, 1000);
    return () => clearInterval(timerRef.current!);
  }, [countdownExam]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds} Min`;
  };

  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const countdown = useCallback(() => {
    if (timeLeftRef.current <= 1) {
      clearInterval(timerRef.current!);
      handleExamEnd("timeout");
      setDisplayedTime(0);
    } else {
      timeLeftRef.current -= 1;
      setDisplayedTime(timeLeftRef.current);
    }
  }, []);

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(countdown, 1000);
    }
    return () => {
      clearInterval(timerRef.current!);
      timerRef.current = null;
    };
  }, [countdown]);

  const handleAnswer = (answer: string) => {
    const opcionId = currentQuestion.opciones.find(
      (opt) => opt.texto === answer
    )?.id;

    if (opcionId) {
      setAnswers((prev) => {
        const existingAnswerIndex = prev.findIndex(
          (ans) => ans.preguntaId === currentQuestion.id
        );
        if (existingAnswerIndex > -1) {
          const updatedAnswers = [...prev];
          updatedAnswers[existingAnswerIndex] = {
            preguntaId: currentQuestion.id,
            opcionId,
          };
          return updatedAnswers;
        } else {
          return [...prev, { preguntaId: currentQuestion.id, opcionId }];
        }
      });
    }
    setCurrentAnswer(answer);
  };

  const handleNext = (skip?: boolean) => {
    const correctOption = currentQuestion.opciones.find(
      (opt) => opt.esCorrecta
    );
    if (currentAnswer === correctOption?.texto) {
      setScore(score + currentQuestion.puntos);
    }

    if (questionIndex < currentModule.preguntas.length - 1) {

      setQuestionIndex(questionIndex + 1);
      if ((currentQuestionNumber + 1) % 5 === 0) {
        saveAnswers();
      }
  
    } else {
      if (moduleIndex < examModules.length - 1) {
        setShowModuleEnd(true);
      }else{
        setShowModuleEnd(true);
        setLastModule(true);

      }
    }
    const nextQuestion = questionIndex + 1;
    if (currentModule.preguntas[nextQuestion]) {
      const nextAnswer = answers.find(
        (ans) => ans.preguntaId === currentModule.preguntas[nextQuestion].id
      );

      setCurrentAnswer(
        nextAnswer
          ? currentModule.preguntas[nextQuestion].opciones.find(
              (opt) => opt.id === nextAnswer.opcionId
            )?.texto
          : undefined
      );
    }
  };

  const handleBack = () => {
    if (questionIndex > 0) {
      const lastQuestion = questionIndex - 1;
      setQuestionIndex(lastQuestion);

      const previousAnswer = answers.find(
        (ans) => ans.preguntaId === currentModule.preguntas[lastQuestion].id
      );
      console.log("previousAnswer", previousAnswer);
      console.log("PREGUNTAS", currentModule.preguntas);
      setCurrentAnswer(
        previousAnswer
          ? currentModule.preguntas[lastQuestion].opciones.find(
              (opt) => opt.id === previousAnswer.opcionId
            )?.texto
          : undefined
      );
    }
  };

  const saveAnswers=(isFinished?: boolean) =>{
    const examResult = {
      respuestas: answers,
      estatus: "en_progreso",
    };

    if (user)
      guardarRespuestasExamen(user.id, exam.id, examResult).then(() => {
        if(isFinished)
        terminarExamen(user.id, exam.id).finally(() => setLoading(false));
      else
        setLoading(false)
      });
  }
  const handleNextModule = () => {
    saveAnswers();

    setModuleIndex(moduleIndex + 1);
    setQuestionIndex(0);
    setShowModuleEnd(false);
  };

  const handleExamEnd = (status: string) => {
    setExamStatus(status);
    setShowResult(true);
  };

  const submitExam = useCallback(() => {
    const examResult = {
      respuestas: answers,
      estatus: "en_progreso",
    };
    if (user) {
      setLoading(true);

      guardarRespuestasExamen(user.id, exam.id, examResult).then(() => {
        terminarExamen(user.id, exam.id).finally(() => setLoading(false));
      });
      logoutService(user.id);

      console.log("RESULT", examResult);
    }
  }, [answers, exam.id, examStatus]);

  useEffect(() => {
    if (showResult) {
      submitExam();
    }
  }, [showResult, submitExam]);
  const navigate = useNavigate();

  if (showResult) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Card
          sx={{ maxWidth: 500, padding: 2, textAlign: "center", boxShadow: 3 }}
        >
          <CardContent>
            <Typography
              variant="h5"
              fontWeight="bold"
              color="primary"
              gutterBottom
            >
              ¡Bien hecho!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Terminaste el exámen de {exam.nombre}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Tu evaluación se está revisando. Te mantendremos informado con los
              resultados.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              color="secondary"
              onClick={() => {
                console.log("!2");
                navigate("/dashboard");
              }}
            >
              {" "}
              Salir{" "}
            </Button>
          </CardContent>
          {loading && <LinearProgress sx={{ mt: 2 }} />}
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          backgroundColor: "#f5f5f5",
          borderRadius: 2,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{exam.nombre}</Typography>
        <Typography variant="body2" color="textSecondary">
          Módulo: {currentModule.nombreModulo}
        </Typography>
        <Button
          color="secondary"
          variant="outlined"
          sx={{ marginRight: 2, borderColor: "#d3d3d3", textTransform: "none" }}
          onClick={() => handleExamEnd("manual")}
        >
          Terminar
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, mx: 2 }}>
          <LinearProgress
            variant="determinate"
            value={lastModule? 100 :progress}
            sx={{
              flexGrow: 1,
              height: 8,
              borderRadius: 5,
              backgroundColor: "#e0e0e0",
              marginRight: 2,
            }}
          />
          <Typography variant="body1" sx={{ minWidth: 40 }}>
            {lastModule? 100 :Math.round(progress)}%
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" color="textSecondary">
            {formatTime(displayedTime)}
          </Typography>
        </Box>
      </Box>

      {showModuleEnd ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
           {lastModule? "Terminaste el módulo y el exámen ":"Terminaste el módulo"} 
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {lastModule? "¿Deseas terminar el exámen?":"¿Deseas avanzar al siguiente módulo?"} 
          
          </Typography>

          <Alert
            severity="error"
            sx={{ maxWidth: 400, margin: "0 auto", mt: 3 }}
          >
           {lastModule? "Una vez que finalices el examen ya no podrás editarlo":"Una vez que comiences un nuevo módulo ya no podrás regresar al anterior"} 

            
          </Alert>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ maxWidth: 400, margin: "0 auto", mt: 3, marginRight: 2 }}
            onClick={() => {setShowModuleEnd(false); setLastModule(false);}}
          >
            Regresar
          </Button>
          {lastModule ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ maxWidth: 400, margin: "0 auto", mt: 3, marginRight: 2 }}
              onClick={  () => {handleExamEnd("completed")
              saveAnswers(true)
            }}
            >
              Terminar examen
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{ maxWidth: 400, margin: "0 auto", mt: 3, marginRight: 2 }}
              onClick={handleNextModule}
            >
              Continuar
            </Button>
          )}
        </Box>
      ) : (
        <Box>
          <Question
            question={currentQuestion}
            onAnswer={handleAnswer}
            onNext={handleNext}
            onBack={handleBack}
            currentAnswer={currentAnswer}
          />
        </Box>
      )}
    </Box>
  );
};

export default Questionnaire;
