import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import { fetchExamsService, fetchModules } from "../../services/examServices";
import { Examen, Modulo } from "../../interfaces/IExam";
import { CircularProgress } from "@mui/material";
import ExamDetail from "./ExamDetail";
import Questionnaire from "../Questionarie/Questionarie";
import ExamCard from "../Cards/ExamCard";
import { useAuth } from "../../context/AuthContext";

const DashboardExams: React.FC = () => {
  const [selectedExam, setSelectedExam] = useState<Examen>();
  const [module, setModules] = useState<Modulo[]>([]);
  const [examenes, setExams] = useState<Examen[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isExamStarted, setIsExamStarted] = useState<boolean>(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user) fetchExams(user?.id);
  }, []);
  const startExam = () => {
    setIsExamStarted(true);
  };
  const loadModuleData = async (ex: Examen) => {
    setLoading(true);
    setSelectedExam(ex);
    try {
      const exams = await fetchModules(ex.id);
      setModules(exams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchExams = async (uid: string) => {
    if (!user) return;
    setLoading(true);
    try {
      const exams = await fetchExamsService(uid);
      setExams(exams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress color="primary" />;
  }
  return (
    <>
      {selectedExam ? (
        <>
          {isExamStarted ? (
            <Card
              sx={{
                minWidth: "80%",
                minHeight: "80vh",
                margin: "0 auto",
                padding: 2,
              }}
            >
              <CardContent>
                <Questionnaire examModules={module} exam={selectedExam} />
              </CardContent>
            </Card>
          ) : (
            <ExamDetail modules={module} startExam={startExam} exam={selectedExam} />
          )}
        </>
      ) : (
        <>
          <CardContent>
            <Typography
              variant="h6"
              fontWeight="bold"
              component="h2"
              gutterBottom
            >
              Exámenes inscritos
            </Typography>
            {examenes.map((examen) => (
              <ExamCard
                key={"card-" + examen.id}
                examen={examen}
                callback={() => loadModuleData(examen)}
                isDetail={true}
              />
            ))}
          </CardContent>
        </>
      )}
    </>
  );
};

export default DashboardExams;
