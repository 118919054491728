import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute: React.FC = () => {
  const { user } = useAuth();
  console.log(user?.nombre+" USER ")
  return user ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
